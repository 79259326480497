import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { OrganizationSmall } from 'webapp/app/shared/models/organization.model';
import { CompanyService } from 'webapp/app/shared/services/company.service';
import { CompanyParams } from '../models/company.model';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, Subscription, from } from 'rxjs';
import { ColumnType, DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';

@Component({
	selector: 'company-list',
	templateUrl: './company-list.component.html',
	styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit, OnDestroy {
	organizationId!: number;
	agentCategories: any[] = [];
	displayEl$: Observable<DataTableList> = new Observable();
	subscriptions: Subscription[] = [];

	constructor(
		private companyService: CompanyService,
		@Inject(NG_ROUTER) private $state: any,
		private i18n: TranslocoService,
		public dialog: MatDialog,
		private notification: NotificationService,
		private mixPanel: MixPanelService
	) {}

	ngOnInit(): void {
		this.getAgentCategories();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	get columnHeaders(): ColumnType[] {
		return [
			{
				label: 'company.table.company',
				type: 'text',
				name: 'name',
			},
			{
				label: 'company.table.cnpj',
				type: 'text',
				name: 'cnpj',
			},
			{
				label: 'company.form.agent-category',
				type: 'text',
				name: 'agentCategoryName',
			},
			{
				label: 'company.table.city',
				type: 'text',
				name: 'district',
			},
			{
				label: 'company.table.state',
				type: 'text',
				name: 'state',
			},
			{
				label: '',
				type: 'action',
				name: 'actions',
			},
		];
	}

	get alertAction(): Action[] {
		return [
			{
				title: this.i18n.translate('global.edit'),
				icon: 'fa-edit',
				type: 'edit',
				permission: 'companyUpdate',
			},
			{
				title: this.i18n.translate('global.delete'),
				icon: 'fa-trash',
				type: 'delete',
				permission: 'companyDelete',
			},
		];
	}
	getAgentCategories() {
		const subscription = from(this.companyService.getAgentCategories())
			.pipe(tap((ac) => this.agentCategories = ac))
			.subscribe();

		this.subscriptions.push(subscription);
	}

	buildTable() {
		const params: CompanyParams = {
			organization: this.organizationId,
		};
		this.displayEl$ = this.companyService.__getCompanies(params).pipe(
			map((res: any) => {
				const datatable: DataTableList = {} as DataTableList;
				datatable.data = res.companies.map((company) => {
					const agentCategory =
						this.agentCategories.find((category) => category.id === company.agentClass?.agentCategory) ||
						this.agentCategories.find((category) => category.name === 'not-agent');

					return {
						id: company.id,
						cnpj: company.registeredNumber,
						district: company.city,
						name: company.tradingName,
						agentCategoryName: agentCategory.label,
						state: company.state,
						action: this.alertAction,
					};
				});
				datatable.badge = {
					singular: this.i18n.translate('company.table.badge'),
					plural: this.i18n.translate('company.table.badges'),
				};
				datatable.column = this.columnHeaders;
				return datatable;
			})
		);
	}

	mixPanelEvent(type) {
		this.mixPanel.mixPanelEvent({ type, object: {} });
	}

	onActionCliked($event: Action) {
		switch ($event.type) {
		case 'edit':
			this.toEdit(<number>$event.id);
			break;
		case 'delete':
			this.removeDialog(<number>$event.id);
			break;
		default:
			break;
		}
	}

	removeDialog(id: number) {
		const confirmDialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		confirmDialogModel.icon = 'error';
		confirmDialogModel.iconType = 'error';
		confirmDialogModel.title = this.i18n.translate('company.msg-delete');
		confirmDialogModel.message = this.i18n.translate('company.msg-delete-2');
		confirmDialogModel.btnError = true;
		const dialogRef = this.dialog.open(GaContentDialogComponent, {
			data: confirmDialogModel,
			width: '407px',
		});
		const subscription = dialogRef.componentInstance.confirm
			.pipe(
				tap(() => {
					this.companyService
						.deleteCompany(id)
						.pipe(
							tap(() => {
								this.notification.success({
									msg: this.i18n.translate('company.msg-delete-success'),
								});
								this.buildTable();
								dialogRef.close();
							}),
							catchError((error) => {
								this.notification.error({
									msg: this.i18n.translate('company.delete-error-msg'),
								});
								return error;
							})
						)
						.subscribe();
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	toEdit(id: number) {
		this.$state.transitionTo('main.companies-detail', { id });
	}

	toNew() {
		this.mixPanelEvent('click_button_company_create');
		this.$state.transitionTo('main.companies-new');
	}

	orgSelected(organization: OrganizationSmall) {
		if (!organization?.id) return;
		this.organizationId = organization.id;
		this.buildTable();
	}
}

export const ng2CompanyListComponent = {
	name: 'companyList',
	def: downgradeComponent({
		component: CompanyListComponent,
		propagateDigest: true,
	}),
};
