import { Component, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { EnergyBillService } from 'webapp/app/shared/services/energy-bill.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { ConsumingUnitListService } from './consuming-unit-list.service';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';

@Component({
	selector: 'consuming-unit-list',
	templateUrl: './consuming-unit-list.component.html',
	styleUrls: ['./consuming-unit-list.component.scss'],
})
export class ConsumingUnitListComponent {
	organizationId!: number;
	displayEl$: Observable<DataTableList> = new Observable();
	dialogRef!: MatDialogRef<any>;

	constructor(
		@Inject(NG_ROUTER) private $state: any,
		@Inject(TranslocoService) private i18n,
		public dialog: MatDialog,
		private energyBillService: EnergyBillService,
		private consumingUnitListService: ConsumingUnitListService,
		private notification: NotificationService,
		private mixpanel: MixPanelService
	) {}

	stateGo(route, params = {}) {
		this.$state.transitionTo(route, params);
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	orgSelected(event: { id: number; name: string }) {
		if (!event?.id || event?.id === this.organizationId) return;
		this.organizationId = event?.id;

		this.listConsumingUnits();
	}

	onActionCliked(event: Action) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		switch (event.type) {
		case 'edit':
			this.stateGo('main.uc', {
				id: event.row.id,
				metadata: event.row,
			});
			return;
		case 'delete':
			dialogModel.icon = 'error';
			dialogModel.iconType = 'error';
			dialogModel.title = this.i18n.translate(
				'consuming-unit-list.modal-delete.title'
			);
			dialogModel.message = this.i18n.translate(
				'consuming-unit-list.modal-delete.message'
			);
			dialogModel.btnError = true;
			break;
		default:
			break;
		}

		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '402px',
		});

		this.dialogRef.componentInstance.confirm
			.pipe(
				tap(() => {
					switch (event.type) {
					case 'delete':
						this.deleteUC(event.row);
						break;
					default:
						break;
					}
				})
			)
			.subscribe();
	}

	deleteUC(uc) {
		this.closeDialog();
		this.energyBillService
			.deleteMetadata(uc.id)
			.then(() => {
				this.listConsumingUnits();
				const msg = this.i18n.translate(
					'consuming-unit-list.notify.delete-success'
				);
				this.notification.success({ msg });
			})
			.catch((error) => {
				this.notification.showErrorMessages(error);
			});
	}

	closeDialog() {
		this.dialogRef.close();
	}

	listConsumingUnits() {
		const params = this.organizationId
			? { organization: this.organizationId }
			: {};
		this.displayEl$ = this.consumingUnitListService
			.listConsumingUnits(params)
			.pipe(
				map((data) => {
					const element: DataTableList = {} as DataTableList;
					element.data = this.consumingUnitListService.renderListToTable(
						data.energyBillMetadatas
					);
					element.badge =
						data.energyBillMetadatas.length === 1
							? this.i18n.translate('consuming-unit-list.table.badge')
							: this.i18n.translate('consuming-unit-list.table.badges');
					element.column = this.consumingUnitListService.columns;
					element.filter = this.consumingUnitListService.filterTable;
					return element;
				})
			);
	}
}

export const ng2ConsumingUnitListComponent = {
	name: 'consumingUnitList',
	def: downgradeComponent({
		component: ConsumingUnitListComponent,
		propagateDigest: true,
	}),
};
