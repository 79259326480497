import {
	Directive,
	Input,
	OnInit,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { OrganizationSmall } from '../../models/organization.model';
import { Permission } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { OrganizationService } from '../../services/organization.service';

/**
 * Implementa a funcionalidade de feature toogle de acordo
 * as permissões do usuário e sua respectiva organização
 */
@Directive({ selector: '[wchHasFeature]', exportAs: '[wchHasFeature]' })
export class HasFeatureDirective implements OnInit {
	/**
	 * Permissões necessárias para exibir a parte da tela a que foi atrelada a diretive
	 * @type {string|undefined} Uma string contendo duas substrings separadas por um caracter de espaço
	 */
	@Input('wchHasFeature') permissions: string | Array<any> | undefined;
	@Input() preferAdminRole = false;

	constructor(
		private vcr: ViewContainerRef,
		private tmp: TemplateRef<any>,
		private userService: UserService,
		private organizationService: OrganizationService
	) {}

	async ngOnInit() {
		const currentUser = await this.userService.getCurrentUser();
		this.adminUser(currentUser);
	}

	private async adminUser(currentUser) {
		const userPermissions = currentUser.permissions;
		this.checkAndRenderViewBasedOnPermissions(currentUser, userPermissions);
	}

	private checkAndRenderViewBasedOnPermissions(currentUser, userPermissions) {
		if (!Array.isArray(this.permissions)) {
			this.changedPermission(
				currentUser,
				<string>this.permissions,
				userPermissions
			);
		} else {
			this.changedPermissions(currentUser, this.permissions, userPermissions);
		}
	}

	changedPermission(currentUser, userPermission: string, userPermissions) {
		let checked = false;
		this.organizationService.$obsevableOrganization.subscribe((org) => {
			this.vcr.clear();
			checked = this.check(
				userPermission,
				this.preferAdminRole ? undefined : org?.id,
				currentUser,
				userPermissions
			);
			if (checked || currentUser.isAdmin) {
				this.vcr.createEmbeddedView(this.tmp);
			} else {
				this.vcr.clear();
			}
		});
	}

	changedPermissions(currentUser, permissions: any[], userPermissions) {
		let checked = false;

		/*Filtra a permissão do usuário de acordo a organização */
		this.organizationService.$obsevableOrganization.subscribe((org) => {
			checked = permissions.some((element) =>
				this.check(
					element.permission,
					this.preferAdminRole ? undefined : org?.id,
					currentUser,
					userPermissions
				)
			);
			if (checked) {
				this.vcr.createEmbeddedView(this.tmp);
			} else {
				this.vcr.clear();
			}
		});
	}

	private check(
		permissions: string | undefined,
		organization: OrganizationSmall[] | number | undefined,
		currentUser: any,
		userPermissions: any
	) {
		if (!permissions) return true;
		const paramPermissions = permissions && permissions.trim();

		const isAdmin = currentUser.isAdmin;

		if (isAdmin || !paramPermissions?.length) {
			return true;
		} else {
			const requiredPermissions = paramPermissions
				.split(' ')
				.map((perm) => perm.trim());

			const hasPermission = userPermissions
				.filter((perm) => this.byOrganization(perm, organization))
				.map((perm) => perm.name)
				.some((perm) => requiredPermissions.includes(perm));

			return hasPermission;
		}
	}

	private byOrganization(
		permission: Permission,
		organization: OrganizationSmall[] | number | undefined
	) {
		if (!organization) {
			return true;
		}
		if (Array.isArray(organization)) {
			return organization.some((org) => org.id == permission.organization);
		} else {
			return permission.organization === organization;
		}
	}
}
