<div class="sticky menu-include">
	<div class="main-nav-wrapper" #navWrapper>
		<nav
			#nav
			id="main-nav"
			class="main-nav"
			[ngClass]="{ scrollmenu: scrollMenu == true }"
		>
			<ul id="menu" app-kit-menu #menu>
				<!--Only on mobile-->
				<li class="only-mobile">
					<a ngRouterLink="main.user">
						<span aria-hidden="true" class="fa fa-user"></span>
						<span class="nav-label">{{ "main.menu.account" | transloco }}</span>
						<!--<span class="fa arrow"></span>-->
					</a>
				</li>
				<!--END Only on mobile-->
				<li>
					<span class="submenu">{{ "main.menu.monitoring" | transloco }}</span>
					<ul class="not-collapse">
						<!--- TODO: O branch provavelmente é usado quanto se está em página com abas --->
						<li
							[ngClass]="{
								active:
									screen == 'main.measuring-points' ||
									(branch == true && screen == undefined)
							}"
							*wchHasFeature="'sectionDataSources'"
						>
							<a ngRouterLink="main.measuring-points">
								<i class="fa-regular fa-grid-2"></i>
								<span class="nav-label">{{
									"main.menu.overview" | transloco
								}}</span>
							</a>
						</li>

						<li
							[ngClass]="{
								active:
									(branch != true && screen == undefined) ||
									screen == 'main.dashboard' ||
									screen == 'main.dashboard.meter' ||
									screen == 'main.dashboard.group'
							}"
							*wchHasFeature="'sectionMeasurements'"
						>
							<a ngRouterLink="main.dashboard">
								<i class="fa-regular fa-chart-mixed"></i>
								<span class="nav-label">{{
									"main.menu.measurement" | transloco
								}}</span>
							</a>
						</li>

						<li
							[ngClass]="{
								active:
									screen == 'main.analysis' || screen == 'main.analysis.kpi'
							}"
							*wchHasFeature="'sectionComparativeAnalysis'"
						>
							<a ngRouterLink="main.analysis">
								<i class="fa-regular fa-magnifying-glass-chart"></i>
								<span class="nav-label">{{
									"main.menu.analysis" | transloco
								}}</span>
							</a>
						</li>
						<ng-template
							[wchHasFeature]="'sectionAlerts'"
							[preferAdminRole]="true"
						>
							<li [ngClass]="{ active: screen == 'main.alerts' }">
								<a ngRouterLink="main.alerts">
									<i class="fa-regular fa-bell"></i>
									<span class="nav-label">{{
										"main.menu.alerts" | transloco
									}}</span>
								</a>
							</li>
						</ng-template>
					</ul>
				</li>
				<li>
					<span class="submenu">{{ "main.menu.financial" | transloco }}</span>
					<ul class="not-collapse">
						<li
							[ngClass]="{ active: screen == 'main.freeMarket' }"
							*wchHasFeature="'sectionFreeMarket'"
						>
							<a ngRouterLink="main.freeMarket">
								<i class="fa-regular fa-circle-dollar"></i>
								<span class="nav-label">{{
									"main.menu.free-market" | transloco
								}}</span>
							</a>
						</li>

						<li
							[ngClass]="{ active: screen == 'contracts' }"
							*wchHasFeature="'sectionContract'"
						>
							<a ngRouterLink="contracts">
								<i class="fa-regular fa-file-invoice-dollar"></i>
								<span class="nav-label">{{
									"main.menu.contracts-and-tariffs" | transloco
								}}</span>
							</a>
						</li>
						<li
							[ngClass]="{
								active:
									screen == 'main.economy' || screen == 'main.economy.tasks'
							}"
							*wchHasFeature="'sectionEconomy'"
						>
							<a ngRouterLink="main.economy">
								<i class="fa-regular fa-lightbulb-on"></i>
								<span class="nav-label">{{
									"main.menu.economy" | transloco
								}}</span>
							</a>
						</li>

						<li
							[ngClass]="{
								active:
									screen == 'main.energy-bills' ||
									screen == 'main.energy-bills-list'
							}"
							*wchHasFeature="'energyBillManager'"
						>
							<a ngRouterLink="main.energy-bills">
								<i class="fa-regular fa-file-lines"></i>
								<span class="nav-label">{{
									"main.menu.bills" | transloco
								}}</span>
							</a>
						</li>
					</ul>
				</li>
				<li>
					<span class="submenu">{{ "main.menu.management" | transloco }}</span>
					<ul class="not-collapse">
						<li
							[ngClass]="{ active: screen == 'registrations' }"
							*wchHasFeature="'sectionOrganizations'"
						>
							<a ngRouterLink="registrations">
								<i class="fa-regular fa-pen-to-square"></i>
								<span class="nav-label">{{
									"main.menu.registrations" | transloco
								}}</span>
							</a>
						</li>

						<li
							[ngClass]="{ active: screen == 'main.export' }"
							*wchHasFeature="'sectionExport'"
						>
							<a ngRouterLink="main.export">
								<i class="fa-regular fa-cloud-arrow-down"></i>
								<span class="nav-label">{{
									"main.menu.export" | transloco
								}}</span>
							</a>
						</li>
					</ul>
				</li>

				<li class="only-mobile">
					<a ngRouterLink="logout">
						<span aria-hidden="true" class="fa fa-fw fa-power-off"></span>
						<span class="nav-label">{{ "page.logout-bt" | transloco }}</span>
						<!--<span class="fa arrow"></span>-->
					</a>
				</li>
			</ul>
			<ul class="footer-menu" #footerMenu>
				<li
					*ngIf="currentUser.organizationObj?.contactUs"
					[ngClass]="{ active: screen == 'main.contact-us' }"
				>
					<a (click)="goTocontactUs()">
						<i class="fa-regular fa-message-dots"></i>
						<span class="nav-label">{{
							"main.menu.contact-us" | transloco
						}}</span>
					</a>
				</li>
				<li>
					<a
						href="https://dashboardgreenant.tawk.help/"
						target="_blank"
						(click)="mixPanelEvent('help_button_click')"
					>
						<i class="fa-regular fa-circle-question"></i>
						<span class="nav-label">{{ "main.menu.help" | transloco }}</span>
					</a>
					<span *ngIf="footerImages.length > 0">
						<div *ngFor="let footerImage of footerImages">
							<img
								[src]="footerImage.small"
								alt=""
								class="full-footer-logo"
								[ngClass]="footerImage.smallImageClass"
							/>
							<img
								[src]="footerImage.textImage"
								alt=""
								class="small-footer-logo"
								[ngClass]="footerImage.textImageClass"
							/>
						</div>
					</span>
				</li>
			</ul>
		</nav>
	</div>
</div>
