import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, Subscription, from } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { OrganizationSmall, UserOrganizationRole } from '../../models/organization.model';
import { OrganizationService } from '../../services/organization.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user.model';

@Component({
	selector: 'autocomplete-orgs',
	templateUrl: './autocomplete-orgs.component.html',
	styleUrls: ['./autocomplete-orgs.component.scss'],
})
export class AutocompleteOrgsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() idSelected: any;
	@Input() isRequired = false;
	@Input() isEnabled = true;
	@Input() adminRoleOnly = false;
	@Output() orgEmitter: EventEmitter<any> = new EventEmitter();
	filteredOrgs$: Observable<any[]> = new Observable();
	orgInput: FormControl = new FormControl('');
	listOrgs: OrganizationSmall[] = [];
	currentUser: User = {} as User;
	currentUserRoleOrgs: UserOrganizationRole[] = [];
	orgSubs: Subscription = {} as Subscription;
	isOrgAdmin = false;

	constructor(private organizationService: OrganizationService, private userService: UserService) {}

	ngOnDestroy(): void {
		this.orgSubs.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.idSelected && changes.idSelected.previousValue !== changes.idSelected.currentValue) {
			this.checkSelected(!changes.idSelected.currentValue && changes.idSelected.currentValue == this.idSelected);
		}
		this.isDisabled();
	}

	formControlRequired() {
		if (this.isRequired) {
			this.orgInput.setValidators([Validators.required]);
			this.orgInput.updateValueAndValidity();
		}
	}

	isDisabled() {
		if (!this.isEnabled) {
			this.orgInput.disable();
			this.orgInput.updateValueAndValidity();
		}
	}

	ngOnInit(): void {
		this.loadOrgs();
		this.isDisabled();
		this.filterOrgsValuesChanges();
		this.formControlRequired();
	}

	filterOrgsValuesChanges() {
		this.filteredOrgs$ = this.orgInput.valueChanges.pipe(
			startWith(''),
			map((value: string) => {
				if (value) return this._filterOrgs(value);
				return this.listOrgs;
			})
		);
	}

	selectOrg(value) {
		this.idSelected = value.id;
		this.organizationService.sessionOrg = value;
		this.emitEvent(value);
	}

	checkSelected(skipStorage = false) {
		const storageOrgstring = sessionStorage.getItem('selectedOrg');
		/* was returning a string of undefined */
		let storageOrg = {} as OrganizationSmall;
		if (storageOrgstring !== undefined) {
			storageOrg = storageOrgstring ? JSON.parse(storageOrgstring) : {};
		}
		if (!skipStorage && storageOrg.id) {
			this.idSelected = storageOrg.id;
		}
		if (this.idSelected) {
			const org = this.listOrgs.find((org) => org.id == this.idSelected);
			if (org)
				setTimeout(() => {
					this.orgInput.setValue(`${org.id} - ${org.name}`);
					this.emitEvent(org);
				}, 0);
		} else if (this.listOrgs.length == 1) {
			this.orgInput.setValue(`${this.listOrgs[0].id} - ${this.listOrgs[0].name}`);
			this.emitEvent(this.listOrgs[0]);
		} else if (storageOrgstring) {
			this.orgInput.setValue(null);
			this.orgInput.markAsTouched();
			this.emitEvent(null);
		}
		this.setAdminRole();
	}

	checkClear(event) {
		if (event.key !== 'Delete' && event.key !== 'Backspace') return;
		this.idSelected = undefined;
		this.orgInput.setValue(null);
		this.orgInput.markAsTouched();
		this.organizationService.sessionOrg = '';
		this.emitEvent(undefined);
	}

	emitEvent(value) {
		this.orgEmitter.emit(value);
	}

	private loadOrgs() {
		const filterPredicate = (obj) => obj.role.label === 'admin';
		this.orgSubs = from(this.userService.getCurrentUser())
			.pipe(
				switchMap((user) => {
					this.currentUser = user;
					return from(this.organizationService.getUserRoleOrganizations(user?.id));
				}),
				tap((orgList) => {
					if (!orgList) return;
					this.currentUserRoleOrgs = orgList;
					const tempOrg = orgList
						?.filter(this.adminRoleOnly && !this.currentUser.isAdmin ? filterPredicate : () => true)
						.map((org) => org.organization)
						.sort(this.compareAsc);

					this.listOrgs = tempOrg.filter((x, i) => tempOrg.indexOf(tempOrg.find((t) => t.id === x.id)) === i);

					this.orgInput.setValue('');
					this.checkSelected();
				})
			)
			.subscribe();
	}

	compareAsc(a, b) {
		if (a.id < b.id) {
			return -1;
		}
		if (a.id > b.id) {
			return 1;
		}
		return 0;
	}

	setAdminRole() {
		const selectedOrgUserRole = this.currentUserRoleOrgs
			.filter((orgRole) => orgRole.user === this.currentUser.id)
			.find((orgRole) => orgRole.organization.id === this.idSelected);

		this.isOrgAdmin = !!selectedOrgUserRole && selectedOrgUserRole.role.label === 'admin';
	}

	private _filterOrgs(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.listOrgs.filter(
			(option: OrganizationSmall) =>
				option.id.toString().includes(filterValue) || option.name.toLowerCase().includes(filterValue)
		);
	}
}
