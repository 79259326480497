import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as moment from 'moment';
import { HttpUtil } from 'webapp/app/shared/services/http.util';
import { AUXILIAR } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'any' })
export class ExportService {
	constructor(
		private HttpUtil: HttpUtil,
		private http: HttpClient,
		@Inject(AUXILIAR) private auxiliar
	) {}

	exportMeterMeasurements(exportOptions) {
		const params = {
			id: exportOptions.meter.id,
			startDate: moment(exportOptions.startDate).format('YYYY-MM-DD'),
			endDate: moment(exportOptions.endDate).format('YYYY-MM-DD'),
			resolution: exportOptions.resolution,
			allParams: exportOptions.allParams == 'true',
		};
		return this.exportData(exportOptions, params, '/api/meters/');
	}

	exportGroupMeasurements(exportOptions) {
		const params = {
			id: exportOptions.group.id,
			startDate: moment(exportOptions.startDate).format('YYYY-MM-DD'),
			endDate: moment(exportOptions.endDate).format('YYYY-MM-DD'),
			resolution: exportOptions.resolution,
			allParams: exportOptions.allParams == 'true',
		};
		return this.exportData(
			exportOptions,
			params,
			`/api/branches/${exportOptions.group.branchId}/groups/`
		);
	}

	private exportData(exportOptions, params, urlPrefix) {
		const formats = {
			xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			csv: 'text/csv',
		};

		let url = '';
		if (exportOptions.resolution == 'invoice') {
			url = urlPrefix + params.id + '/measurements/total';
			params.type = 'invoice';
		} else {
			url = urlPrefix + params.id + '/measurements';
		}

		return this.HttpUtil.get({
			url: url,
			headers: { accept: formats[exportOptions.format] },
			responseType: 'arraybuffer',
			params: params,
		}).then((res) => this.processDownload(res));
	}

	exportDataSource(exportOptions) {
		let url = `/api/datasources/${exportOptions.dataSource.id}/measurements`;
		const params: any = {
			startDate: moment(exportOptions.startDate).format('YYYY-MM-DD'),
			endDate: moment(exportOptions.endDate).format('YYYY-MM-DD'),
			resolution: exportOptions.resolution,
			allParams: exportOptions.allParams == 'true',
		};

		const formats = {
			xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			csv: 'text/csv',
		};

		if (exportOptions.resolution == 'invoice') {
			url += '/total';
			params.type = 'invoice';
		}

		return this.HttpUtil.get({
			url: url,
			headers: { accept: formats[exportOptions.format] },
			responseType: 'arraybuffer',
			params: params,
		}).then((res) => this.processDownload(res));
	}

	exportDataSourcesEmail(exportOptions) {
		const url = '/api/datasources/measurements/ids';
		const params: any = {
			startDate: exportOptions.startDate,
			endDate: exportOptions.endDate,
			resolution: exportOptions.resolution,
			allParams: exportOptions.infoType === 'electrical' ? 'true' : 'false',
			dataSources: exportOptions.dataSources.join(','),
			organizationId: exportOptions.organization,
			infoType: exportOptions.infoType,
			email: exportOptions.email,
		};

		const formats = {
			xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			csv: 'text/csv',
		};

		const headers = new HttpHeaders({
			Accept: formats[exportOptions.format],
		});

		return this.http.post(`${environment.backendUrl}${url}`, params, {
			headers: headers,
		});
	}

	private processDownload(res) {
		this.auxiliar.download(
			res,
			'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		);
		return true;
	}

	private handleError(error) {
		const dataView = new DataView(error.data);
		const decoder = new TextDecoder('utf8');
		throw {
			data: JSON.parse(decoder.decode(dataView)),
		};
	}

	exportTariffs(exportParams, acceptFormat) {
		const downForm = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		exportParams.export = true;
		return this.HttpUtil.get({
			url: '/api/tariffInstances',
			params: exportParams,
			headers: {
				accept: acceptFormat,
			},
			responseType: 'arraybuffer',
		})
			.then((res) => this.auxiliar.download(res, downForm))
			.catch((error) => {
				const dataView = new DataView(error.data);
				const decoder = new TextDecoder('utf8');
				throw {
					data: JSON.parse(decoder.decode(dataView)),
				};
			});
	}

	exportEnergyBillPdf(params) {
		return this.http
			.post(
				`${environment.backendUrl}/api/energyBills/batchPDFDownload`,
				params
			)
			.toPromise();
	}

	exportEnergyBillsXlsx(params) {
		return this.http
			.post(`${environment.backendUrl}/api/energyBills/extractToExcel`, params)
			.toPromise();
	}
}

export const ng2ExportService = {
	name: ExportService.name,
	def: downgradeInjectable(ExportService),
};
