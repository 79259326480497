import {
	Component,
	Inject,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from 'angular-ui-router';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import * as moment from 'moment';
import { DataSourceService } from 'webapp/app/shared/services/datasource.service';
import { MeterService } from 'webapp/app/shared/services/meter.service';
import { CookieService } from 'ngx-cookie-service';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { MeasurementsImportComponent } from '../measurements-import/measurements-import.component';
import { Observable } from 'rxjs';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { DatasourceManagerService } from './datasource-manager.service';
import { GaDatatableNewComponent } from 'webapp/app/shared/ga-datatable-new/ga-datatable-new.component';
import { ActionButton } from 'webapp/app/shared/models/selector.model';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';
@Component({
	selector: 'datasource-manager',
	templateUrl: './datasource-manager.component.html',
	styleUrls: ['./datasource-manager.component.scss'],
})
export class DatasourceManagerComponent implements OnInit {
	@ViewChild(MeasurementsImportComponent)
		measurementsImport!: MeasurementsImportComponent;
	@ViewChild(GaDatatableNewComponent)
		dataTable!: GaDatatableNewComponent;
	@ViewChild('activeTemplate') activeTemplate!: TemplateRef<any>;
	@ViewChild('changeTemplate') changeTemplate!: TemplateRef<any>;
	@ViewChild('importMeasurementTemplate')
		importMeasurementTemplate!: TemplateRef<any>;
	organizationName = '';
	organizationId: number | undefined = undefined;
	dataMedicao = new FormControl('', Validators.required);
	codigoMedidor = new FormControl('', Validators.required);
	dialogRef!: MatDialogRef<any>;
	displayEl$: Observable<any> = new Observable();
	actionButtons: ActionButton[] = [];

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(TranslocoService) private i18n: TranslocoService,
		public dialog: MatDialog,
		private cookie: CookieService,
		private dataSourceService: DataSourceService,
		private meterService: MeterService,
		private notification: NotificationService,
		private datasourceManagerService: DatasourceManagerService,
		private mixpanel: MixPanelService
	) {}

	ngOnInit(): void {
		this.initActionButtons();
		const flowMessage = this.cookie.get('flowMessage-dataSource');
		if (flowMessage) {
			this.notification.success({ msg: flowMessage });
			this.cookie.delete('flowMessage-dataSource');
		}
	}

	newAction(event) {
		let route = '';
		const params: any = {
			id: null,
			organizationId: this.organizationId,
		};
		switch (true) {
		case event.actionName === 'new-measuring-point':
			this.mixPanelEvent('click_button_datasource_point_create');
			route = 'main.measuring-points-meter';
			break;
		case event.actionName === 'new-group':
			this.mixPanelEvent('click_button_datasource_group_create');
			route = 'main.measuring-points-group';
			break;
		case event.actionName === 'import':
			this.mixPanelEvent('click_button_datasource_import');
			route = 'main.measuring-points-upload';
			break;
		}
		this.stateGo(route, params);
	}

	orgSelected(event: { id: number; name: string }) {
		if (!event?.id || event?.id === this.organizationId) return;
		this.organizationName = event?.name;
		this.organizationId = event?.id;

		this.listDatasources();
	}

	listDatasources() {
		const params = this.organizationId
			? { organizationId: this.organizationId }
			: {};
		this.displayEl$ = this.datasourceManagerService
			.listDataSources(params)
			.pipe(
				map((data) => {
					const element: DataTableList = {} as DataTableList;
					element.data = this.datasourceManagerService.renderListToTable(
						data.dataSources
					);
					element.column = this.datasourceManagerService.columns;
					element.badge =
						data.dataSources.length === 1
							? this.i18n.translate('data-table.local')
							: this.i18n.translate('data-table.locals');
					element.sub = this.datasourceManagerService.subColumns;
					element.filter = this.datasourceManagerService.filterTable;
					return element;
				})
			);
	}

	getDataSourceUsers(event) {
		if (!event || !event.length) return;
		const params = this.organizationId
			? { organizationId: this.organizationId }
			: {};

		event.forEach((dataSource) => {
			if (dataSource.users.length) return;
			const users = this.datasourceManagerService.renderUserList(
				dataSource.id,
				params
			);
			dataSource.users = users;
		});
	}

	onRowExpand(event) {
		if (!event || !event.groupMeters) return;
		const params = this.organizationId
			? { organizationId: this.organizationId }
			: {};

		event.groupMeters.forEach((dataSource) => {
			if (dataSource.users.length) return;
			const users = this.datasourceManagerService.renderUserList(
				dataSource.id,
				params
			);
			dataSource.users = users;
		});
	}

	stateGo(route, params) {
		this.$state.transitionTo(route, params);
	}

	initActionButtons() {
		const translateCodes = [
			'main.measuring-points.new-measuring-point',
			'main.measuring-points.new-measuring-point-subtitle',
			'main.measuring-points.new-group',
			'main.measuring-points.new-group-subtitle',
			'import.measurement-point.upload',
			'import.measurement-point.desc',
		];
		this.i18n.selectTranslate(translateCodes).subscribe((labels) => {
			this.actionButtons = [
				{
					icon: 'fa-circle-bolt',
					title: labels[0],
					subtitle: labels[1],
					actionName: 'new-measuring-point',
				},
				{
					icon: 'fa-draw-circle',
					title: labels[2],
					subtitle: labels[3],
					actionName: 'new-group',
				},
				{
					icon: 'fa-cloud-upload',
					title: labels[4],
					subtitle: labels[5],
					actionName: 'import',
				},
			];
		});
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	openDialog(event) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		let transitionParams = {};
		this.dataMedicao.setValue(new Date());
		switch (event.type) {
		case 'edit':
			transitionParams = {
				organizationId: this.organizationId,
				id: event.row.id,
			};
			if (event.row.meterType) {
				this.stateGo('main.measuring-points-meter', transitionParams);
			} else {
				this.stateGo('main.measuring-points-group', transitionParams);
			}
			return;
		case 'active':
			dialogModel.icon = 'play-pause';
			dialogModel.title = this.i18n.translate('meter-page.button.active');
			dialogModel.message = this.i18n.translate(
				'meter-page.modal.start-date-msg'
			);
			dialogModel.template = this.activeTemplate;
			break;
		case 'inactive':
			dialogModel.icon = 'play-pause';
			dialogModel.title = this.i18n.translate('meter-page.button.inactive');
			dialogModel.message = this.i18n.translate(
				'meter-page.modal.end-date-msg'
			);
			dialogModel.template = this.activeTemplate;
			break;
		case 'meter-change':
			dialogModel.icon = 'change';
			dialogModel.title = this.i18n.translate('meter-page.button.change');
			dialogModel.message = this.i18n.translate(
				'meter-page.modal.change-date-msg'
			);
			dialogModel.template = this.changeTemplate;
			break;
		case 'delete':
			dialogModel.icon = 'error';
			dialogModel.iconType = 'error';
			dialogModel.title = this.i18n.translate(
				'meter-page.modal.shure-to-delete'
			);
			dialogModel.message = this.i18n.translate(
				'meter-page.modal.can-lose-data'
			);
			dialogModel.btnError = true;
			break;
		case 'measurements-import':
			this.mixPanelEvent('click_button_measurement_import');
			dialogModel.title = this.i18n.translate('import.measurements.import');
			dialogModel.message = this.i18n.translate(
				'import.measurements.subtitle'
			);
			dialogModel.template = this.importMeasurementTemplate;
			dialogModel.btnMessage = 'meter-page.button.send';
			break;
		}

		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: event.type === 'measurements-import' ? '600px' : '407px',
		});

		this.dialogRef.componentInstance.confirm
			.pipe(
				tap(() => {
					switch (event.type) {
					case 'active':
						this.startCycle(event.row);
						break;
					case 'inactive':
						this.finishCycle(event.row);
						break;
					case 'meter-change':
						this.changeMeterDevice(event.row);
						break;
					case 'delete':
						this.onDelete(event.row);
						break;
					case 'measurements-import':
						this.onUploadMeasurements();
						break;
					}
				})
			)
			.subscribe();
	}

	setModalLoading(event) {
		this.dialogRef.componentInstance.loading = event;
	}

	changeModalContent(event) {
		Object.keys(event).forEach(
			(key) => (this.dialogRef.componentInstance[key] = event[key])
		);
	}

	startCycle(dataSource) {
		this.meterService
			.saveDeviceInstallation({
				meterId: dataSource.id,
				startDate: moment(
					this.dataMedicao.value,
					'DD/MM/YYYY - HH:mm'
				).format(),
			})
			.then(() => {
				this.notification.success({
					msg: this.i18n.translate('meter-page.validation.success-start-cycle'),
				});
				this.listDatasources();
				this.closeDialog();
			})
			.catch((error) => {
				this.notification.showErrorMessages(error);
				this.closeDialog();
			});
	}

	async finishCycle(dataSource) {
		moment.locale('pt-BR');
		const finishDate = moment(
			this.dataMedicao.value,
			'DD/MM/YYYY - HH:mm'
		).format();

		if (
			moment(this.dataMedicao.value).isBefore(
				dataSource.deviceInstallation.startDate
			)
		) {
			this.dataMedicao.setErrors({ invalidDate: true });

			return;
		}
		this.meterService
			.saveDeviceInstallation({
				...dataSource.deviceInstallation,
				endDate: finishDate,
			})
			.then(() => {
				this.notification.success({
					msg: this.i18n.translate('meter-page.validation.success-close-cycle'),
				});
				this.listDatasources();
				this.closeDialog();
			})
			.catch((error) => {
				this.notification.showErrorMessages(error);
				this.closeDialog();
			});
	}

	async changeMeterDevice(dataSource) {
		const meter = { ...dataSource };

		meter.uid = this.codigoMedidor.value;
		if (!meter.uid) {
			this.notification.error({
				msg: this.i18n.translate('meter-page.validation.uid-not-informed'),
			});
			return;
		}

		meter.startDate = moment(
			this.dataMedicao.value,
			'DD/MM/YYYY - HH:mm'
		).format();

		this.meterService
			.saveMeter(meter)
			.then(() => {
				this.notification.success({
					msg: this.i18n.translate(
						'meter-page.validation.success-change-meter'
					),
				});
				this.listDatasources();
				this.closeDialog();
				this.codigoMedidor.setValue('');
			})
			.catch((error) => {
				this.notification.showErrorMessages(error);
				this.closeDialog();
				this.codigoMedidor.setValue('');
			});
	}

	onDelete(dataSource) {
		this.dataSourceService
			.deleteDataSource(dataSource.id)
			.then(() => {
				this.notification.success({
					msg: dataSource.meterTypeId
						? this.i18n.translate('meter-page.validation.meter-deleted-sucess')
						: this.i18n.translate('meter-page.validation.group-deleted-sucess'),
				});
				this.listDatasources();
				this.closeDialog();
			})
			.catch((error) => {
				this.notification.showErrorMessages(error);
				this.closeDialog();
			});
	}

	closeDialog() {
		this.dialogRef.close();
	}

	onUploadMeasurements() {
		const response = this.dialogRef.componentInstance.response;
		if (!response) {
			this.measurementsImport.upload();
			return;
		}

		this.measurementsImport.send();
	}
}

export const ng2DatasourceManagerComponent = {
	name: 'datasourceManager',
	def: downgradeComponent({
		component: DatasourceManagerComponent,
		propagateDigest: true,
	}),
};
