import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import { GaDatatableNewComponent } from 'webapp/app/shared/ga-datatable-new/ga-datatable-new.component';
import { Observable, Subscription } from 'rxjs';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { StateService } from 'angular-ui-router';
import { TranslocoService } from '@ngneat/transloco';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';
import { catchError, map, tap } from 'rxjs/operators';
import { AutocompleteOrgsComponent } from 'webapp/app/shared/autocomplete-components/organizations/autocomplete-orgs.component';
import { CertificateListService } from './certificate-list.service';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';

@Component({
	selector: 'certificate-list',
	templateUrl: './certificate-list.component.html',
	styleUrls: ['./certificate-list.component.scss'],
})
export class CertificateListComponent {
	@ViewChild(GaDatatableNewComponent)
		dataTable!: GaDatatableNewComponent;
	@ViewChild(AutocompleteOrgsComponent)
		organizationsComponent!: AutocompleteOrgsComponent;
	organizationId: number | undefined = undefined;
	dialogRef!: MatDialogRef<any>;
	displayEl$: Observable<any> = new Observable();
	subscriptions: Array<Subscription> = [];
	isOrgAdmin = false;

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(TranslocoService) private i18n: TranslocoService,
		public dialog: MatDialog,
		private mixpanel: MixPanelService,
		private certificateListService: CertificateListService,
		private notificationService: NotificationService
	) {}

	orgSelected(event: { id: number; name: string }) {
		if (!event?.id || event?.id === this.organizationId) return;
		this.organizationId = event?.id;

		this.isOrgAdmin =
			this.organizationsComponent.isOrgAdmin ||
			this.organizationsComponent.currentUser.isAdmin;

		this.listCertificates();
	}

	listCertificates() {
		const params = this.organizationId
			? { organization: this.organizationId }
			: {};
		this.displayEl$ = this.certificateListService.listCertificates(params).pipe(
			map((data) => {
				const element: DataTableList = {} as DataTableList;
				element.data = this.certificateListService.renderListToTable(
					data.certificates
				);
				element.column = this.certificateListService.columns;
				element.badge =
					data.certificates.length === 1
						? this.i18n.translate('certification.list.badge')
						: this.i18n.translate('certification.list.badges');

				return element;
			})
		);
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	doAction(event) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		let route = 'main.certificate-new';
		const params: any = {
			organizationId: this.organizationId,
		};
		switch (event.type) {
		case 'new': {
			this.stateGo(route, params);
			this.mixPanelEvent('click_button_certificate_create');
			return;
		}
		case 'edit': {
			params.id = event.row.id;
			route = 'main.certificate-detail';
			this.mixPanelEvent('click_button_certificate_edit');
			this.stateGo(route, params);
			return;
		}
		case 'delete':
			dialogModel.icon = 'error';
			dialogModel.iconType = 'error';
			dialogModel.title = this.i18n.translate(
				'composition-list.notify.message.shure-to-delete'
			);
			dialogModel.message = this.i18n.translate(
				'composition-list.notify.message.can-lose-data'
			);
			dialogModel.btnError = true;
			break;
		}
		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '407px',
		});

		this.subscriptions.push(
			this.dialogRef.componentInstance.confirm
				.pipe(
					tap(() => {
						this.onDelete(event.row);
					})
				)
				.subscribe()
		);
	}

	onDelete(certificate) {
		const subscription = this.certificateListService
			.deleteCertificate(certificate.id)
			.pipe(
				tap(() => {
					this.notificationService.success({
						msg: this.i18n.translate('certification.list.delete-success'),
					});
					this.listCertificates();
					this.closeDialog();
				}),
				catchError((error) => {
					this.notificationService.showErrorMessages(error);
					this.closeDialog();
					return error;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	closeDialog() {
		this.dialogRef.close();
	}

	stateGo(route, params) {
		this.$state.transitionTo(route, params);
	}
}

export const ng2CertificateListComponent = {
	name: 'certificateList',
	def: downgradeComponent({
		component: CertificateListComponent,
		propagateDigest: true,
	}),
};
