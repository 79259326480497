import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { TagList } from 'webapp/app/shared/models/tag-list.model';
import { GASelectorLink } from 'webapp/app/shared/ga-datatable-new/model/ga-link.model';

@Injectable({
	providedIn: 'root',
})
export class TagsManagerService {
	constructor(private i18n: TranslocoService) {}

	get columnHeaders(): ColumnType[] {
		return [
			{
				label: 'tags.table.label',
				type: 'color',
				name: 'label',
				width: '40%',
			},
			{
				label: 'tags.table.tasks-labeled',
				type: 'selectLink',
				name: 'tasks',
				sortBy: (item: any, property: string) => item[property].length,
				width: '60%',
				paddingRight: '40%',
			},
			{
				label: '',
				type: 'action',
				name: 'actions',
			},
		];
	}

	get alertAction(): Action[] {
		return [
			{
				title: this.i18n.translate('tags.table.edit'),
				icon: 'fa-edit',
				type: 'edit',
			},
			{
				title: this.i18n.translate('tags.table.remove'),
				icon: 'fa-trash',
				type: 'delete',
			},
		];
	}

	renderListToTable(tags: TagList[]) {
		return tags.map((tag) => {
			return {
				id: tag.id,
				label: tag.label,
				color: tag.color,
				tasks: this.renderTasks(tag.objects, tag.organization),
				action: this.alertAction,
			};
		});
	}

	private renderTasks(tasks: any, organization: number): GASelectorLink[] {
		const dropdownList: GASelectorLink[] = [];
		tasks.forEach((task) => {
			const dropdownObj: GASelectorLink = {} as GASelectorLink;
			dropdownObj.placeholder = `${this.i18n.translate('tags.table.tasks')}`;
			dropdownObj.label = task.target;
			dropdownObj.value = task.id;
			dropdownObj.link = 'main.economy.tasks';
			dropdownObj.params = {
				organizationId: organization,
				tab: 'task-manager',
			};
			dropdownList.push(dropdownObj);
		});
		return dropdownList;
	}

	filterTable(data: any, filterText: any) {
		const filter = filterText.toLowerCase();
		return (
			data.label.toLowerCase().includes(filter) ||
			data.tasks.some((n) => n.label.toLowerCase().includes(filter)) ||
			(data.tasks.length === 1 &&
				data.tasks[0].label.toLowerCase().includes(filter))
		);
	}
}
