<div
	class="ga-datalist-content table-responsive table-responsive-ga"
	[ngClass]="{ 'hide-shadow': hideShadow }"
>
	<app-my-loader *ngIf="!hideLoading" [lightMode]="true"></app-my-loader>
	<div class="top-search-bar" [ngClass]="{ hidden: hideSearch }">
		<section
			class="flex-row aling-center top-search-bar__section"
			*ngIf="badge"
		>
			<span class="top-search-bar__section__title">
				{{ tableTitle | transloco }}
			</span>
			<span
				*ngIf="table.data.length"
				class="top-search-bar__section__local ml-2"
			>
				{{ table.filteredData.length }} {{ badge }}
			</span>
		</section>
		<section
			*ngIf="table.data.length"
			class="flex-row aling-center top-search-bar__search-section"
		>
			<div class="top-search-bar__search-section__input">
				<i
					class="fa-regular fa-magnifying-glass top-search-bar__search-section__icon"
				></i>
				<ga-input
					class="top-search-bar__search-section__input"
					[placeholder]="'data-table.search' | transloco"
					[formControl]="searchInput"
				></ga-input>
			</div>
			<div class="top-search-bar__search-section__filters">
				<ng-content select="[externalFilters]"></ng-content>
			</div>
			<div class="dropdown ml-2" style="cursor: pointer">
				<i
					class="fa-regular fa-ellipsis-vertical more-size"
					data-toggle="dropdown"
				></i>
				<ul class="dropdown-menu dropdown-menu-right">
					<li
						(click)="exportTable()"
						[class.disabled]="table.data.length === 0 ? true : null"
					>
						<a>
							<i class="fa-regular fa-cloud-download"></i
							>{{ "data-table.export" | transloco }}
						</a>
					</li>
				</ul>
			</div>
		</section>
	</div>
	<ng-container *ngIf="table.data.length; else emptyTable">
		<table
			mat-table
			[dataSource]="table"
			matSort
			(matSortChange)="emitChangePage()"
			[matSortActive]="firstColumn"
			matSortDirection="asc"
			matSortDisableClear
			id="exporter"
			multiTemplateDataRows
			class="main"
		>
			<ng-container *ngFor="let column of columns" [matColumnDef]="column.name">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header
					[disabled]="!column.label"
					[ngClass]="{
						'expanded-table': !!propertyToExpand
					}"
					[style.width]="column.width || 'none'"
					[style.padding-right]="column.paddingRight || 'none'"
					[attr.sticky]="firstColumn == column.name"
				>
					{{ column.label | transloco }}
					<ga-helper
						*ngIf="column.tooltip"
						[text]="column.tooltip"
						[iconSize]="12"
						[tooltipLocale]="'above'"
						style="margin-left: 3px"
					></ga-helper>
				</th>
				<td
					mat-cell
					*matCellDef="let row"
					[ngClass]="{
						'pd-dropdown': isExpandedTable(column, row),
						'expanded-table': !!propertyToExpand
					}"
					[style.width]="column.width || 'none'"
					[style.padding-right]="column.paddingRight || 'none'"
					[attr.sticky]="firstColumn == column.name"
				>
					<div [class.td-dropdown]="column.isExpanded">
						<span
							class="expanded"
							(click)="expandRow(row)"
							*ngIf="isExpandedTable(column, row)"
						>
							<i
								[ngClass]="!row.isExpanded ? 'expand-close' : 'expand-open'"
								class="fa-solid fa-chevron-right chevron-size"
							></i>
						</span>
						<ng-container
							[ngTemplateOutlet]="templates"
							[ngTemplateOutletContext]="{
								element: row,
								column: column,
								tableId: id
							}"
						>
						</ng-container>
					</div>
				</td>
			</ng-container>
			<ng-container matColumnDef="subRowDef">
				<td
					mat-cell
					*matCellDef="let subRow; let thisRow"
					style="padding: 0px !important; border: 0"
					[attr.colspan]="displayedColumns.length"
				>
					<div
						class="expand-section"
						[@detailExpand]="subRow.isExpanded ? 'expanded' : 'collapsed'"
					>
						<ga-subtable
							*ngIf="subRow.isExpanded"
							[showSubcolumTitle]="showSubcolumTitle"
							[showSubtablePaginator]="showSubtablePaginator"
							[displayedSubColumns]="displayedSubColumns"
							[data]="subRow[propertyToExpand]"
							[subColumns]="subColumns"
							[templates]="templates"
							[tableId]="id"
						>
						</ga-subtable>
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: ['subRowDef']"
				class="sub-table-detail"
			></tr>
		</table>
		<mat-paginator
			#mainPaginator
			id="mainPaginator-{{ id }}"
			[pageSizeOptions]="[15, 25, 30]"
			[showFirstLastButtons]="true"
			(page)="emitChangePage()"
		>
		</mat-paginator>
	</ng-container>

	<ng-template
		#templates
		let-row="element"
		let-column="column"
		let-tableId="tableId"
	>
		<ng-container *ngIf="column.type !== 'custom'">
			<ga-templates-cell
				[column]="column"
				[row]="row"
				[tableId]="tableId"
			></ga-templates-cell>
		</ng-container>
		<!-- transform string to simple html -->
		<ng-container *ngIf="column.type === 'custom'">
			<div [innerHTML]="getSafeHtml(row[column.name])"></div>
		</ng-container>
	</ng-template>

	<ng-template #emptyTable>
		<div class="empty-table">
			<ga-tag-icon
				*ngIf="showElements"
				[size]="sizeIcon"
				[icon]="'error'"
			></ga-tag-icon>
			<div class="empty">
				<ng-content select="[emptyTitle]"></ng-content>
			</div>
			<hr *ngIf="showElements" />
			<div class="empty">
				<ng-content select="[emptySubtitle]"></ng-content>
			</div>
		</div>
	</ng-template>
</div>
