import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { GASelectorLink } from 'webapp/app/shared/ga-datatable-new/model/ga-link.model';
import { LoaderService } from 'webapp/app/shared/global-loading/loader.service';
import { environment } from 'webapp/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UserListService {
	constructor(
		private http: HttpClient,
		private i18n: TranslocoService,
		private loaderService: LoaderService
	) {}

	listUsers(params: any = {}): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/organizations/${params.organizationId}/users`,
			{
				params,
			}
		);
	}

	getDataSourcesOfUser(userId): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/users/${userId}/data-sources`
		);
	}

	renderListToTable(usersList, isAdmin) {
		return usersList.map((user) => {
			const row = {} as any;
			row.id = user.id;
			row.name = this.renderName(user);
			row.organizations = this.generateOptionsToSelect(user.organizations);
			row.dataSources = [];
			row.status = {
				status: user.active,
			};
			row.action = this.getActions(user, isAdmin);

			return row;
		});
	}

	renderName(user) {
		return `
			<div style="display: flex; flex-direction: column">
				<span style="font-weight: 700">${user.firstName} ${user.lastName}</span>
				<small>${user.email}</small>
			</div>
		  `;
	}

	renderDataSourcesPoints(userId): GASelectorLink[] {
		const dropdownList: GASelectorLink[] = [];

		this.getDataSourcesOfUser(userId)
			.pipe(
				tap((dataSources) => {
					dataSources.forEach((meter) => {
						const dropdownObj: GASelectorLink = {} as GASelectorLink;
						dropdownObj.placeholder = `${this.i18n.translate(
							'alerts.label.meter-popuver.title-btn-plural'
						)}`;
						dropdownObj.label = meter.label;
						dropdownObj.value = meter.id;
						dropdownObj.subtext = `${this.i18n.translate('global.code')}: ${
							meter.uid
						}`;
						dropdownObj.link = 'main.dashboard.meter';
						dropdownObj.params = { uid: meter.id };
						dropdownList.push(dropdownObj);
					});
				})
			)
			.subscribe();
		this.loaderService.isLoading.next(false);
		return dropdownList;
	}

	get columns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('user.label.firsName'),
				name: 'name',
				type: 'custom',
				width: '30%',
			},
			{
				label: this.i18n.translate('user.label.org'),
				name: 'organizations',
				type: 'select',
				sortBy: (item: any, property: string) => item[property].length,
				width: '25%',
			},
			{
				label: this.i18n.translate('user.label.datasources'),
				name: 'dataSources',
				type: 'selectLink',
				textCenter: true,
				width: '25%',
			},
			{
				label: this.i18n.translate('user.label.status'),
				name: 'status',
				type: 'active',
				inactiveLabel: 'user.label.inactive',
				sortBy: (item: any, property: string) => item[property].status || '',
				width: '20%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	generateOptionsToSelect(list) {
		const optionsArr = list.map((element) => ({
			id: element.id,
			label: element.name,
			disabled: true,
			placeholder: `${this.i18n.translate('user.dropdown.organization')}`,
		}));

		return optionsArr || [];
	}

	getActions(user, isAdmin): Action[] {
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('user.edit'),
				type: 'edit',
				permission: 'userUpdate',
				row: user,
			},
		];
		if (user.active) {
			actions.push({
				icon: 'fa-square-minus',
				title: this.i18n.translate('user.deactivate'),
				type: 'inactive',
				permission: 'userUpdate',
				row: user,
			});
		} else {
			actions.push({
				icon: 'fa-square-plus',
				title: this.i18n.translate('user.activate'),
				type: 'active',
				permission: 'userUpdate',
				row: user,
			});
		}
		actions.push({
			icon: 'fa-bell',
			title: this.i18n.translate('user.notifications'),
			type: 'notification',
			permission: 'userUpdate',
			row: user,
		});
		if (user.active && isAdmin)
			actions.push({
				icon: 'fa-eye',
				title: this.i18n.translate('user.view-as'),
				type: 'personificate',
				row: user,
			});
		actions.push({
			icon: 'fa-trash',
			title: this.i18n.translate('global.delete'),
			type: 'delete',
			permission: 'userDelete',
			row: user,
		});
		return actions;
	}

	filterTable(data: any, filterText: string) {
		const filter = filterText.toLowerCase();
		const strStatus = data.status.status ? 'ativo' : 'desativado';
		const filterStatus =
			'ativo' === filter ? !!data.status.status : strStatus.includes(filter);

		return (
			data.name.toLowerCase().includes(filter) ||
			(data.organizations.length === 1 &&
				data.organizations[0].label.toLowerCase().includes(filter)) ||
			(data.dataSources.length === 1 &&
				data.dataSources[0].label.toLowerCase().includes(filter)) ||
			`${data.organizations.length} organizações`
				.toLowerCase()
				.includes(filter) ||
			`${data.dataSources.length} medidores`.toLowerCase().includes(filter) ||
			data.dataSources.some((n) => n.label.toLowerCase().includes(filter)) ||
			data.organizations.some((n) => n.label.toLowerCase().includes(filter)) ||
			filterStatus
		);
	}
}
