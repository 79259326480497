import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from 'angular-ui-router';
import { Observable, Subscription } from 'rxjs';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { KpiListService } from './kpi-list.service';
import { catchError, map, tap } from 'rxjs/operators';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { LoaderService } from 'webapp/app/shared/global-loading/loader.service';
import { DateRange } from '@angular/material/datepicker';
import moment from 'moment';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { GaDatatableNewComponent } from 'webapp/app/shared/ga-datatable-new/ga-datatable-new.component';
import { AutocompleteOrgsComponent } from 'webapp/app/shared/autocomplete-components/organizations/autocomplete-orgs.component';
import { User } from 'webapp/app/shared/models/user.model';

@Component({
	selector: 'kpi-list',
	templateUrl: './kpi-list.component.html',
	styleUrls: ['./kpi-list.component.scss'],
})
export class KpiListComponent implements OnInit, OnDestroy {
	@ViewChild(GaDatatableNewComponent)
		dataTable!: GaDatatableNewComponent;
	@ViewChild(AutocompleteOrgsComponent)
		organizationsComponent!: AutocompleteOrgsComponent;
	organizationId: number | undefined = undefined;
	displayEl$: Observable<any> = new Observable();
	selectedMonth: DateRange<Date> | undefined;
	dialogRef!: MatDialogRef<any>;
	subscriptions: Subscription[] = [];
	kpiList: any;
	iskpisLoaded = false;
	currentUser: User = {} as User;
	isOrgAdmin = false;

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(TranslocoService) private i18n,
		private notification: NotificationService,
		private mixpanel: MixPanelService,
		private kpiListService: KpiListService,
		private loaderService: LoaderService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		const today = new Date();
		const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.selectedMonth = new DateRange<Date>(firstDay, lastDay);
	}

	ngOnDestroy(): void {
		this.iskpisLoaded = false;
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	orgSelected(event: { id: number; name: string }) {
		if (!event?.id || event?.id === this.organizationId) return;
		this.organizationId = event?.id;

		this.listKpis();
		this.isOrgAdmin = this.organizationsComponent.isOrgAdmin;
		this.currentUser = this.organizationsComponent.currentUser;
	}

	selectDate() {
		this.kpiListService.calendarData = {
			startDate: moment(this.selectedMonth?.start).format().slice(0, 10),
			endDate: moment(this.selectedMonth?.end).format().slice(0, 10),
		};
		this.getKpiPoints(this.kpiList, true);
	}

	setFilters(event) {
		if (this.iskpisLoaded) this.dataTable.setFilters(event);
	}

	listKpis() {
		const params = this.organizationId
			? { organizationId: this.organizationId }
			: {};
		this.displayEl$ = this.kpiListService.listKpis(params).pipe(
			map((data) => {
				this.iskpisLoaded = true;
				this.kpiListService.kpis = data;
				const element: DataTableList = {} as DataTableList;
				element.data = this.kpiListService.renderListToTable(data);
				element.column = this.kpiListService.columns;
				element.badge =
					data.length === 1
						? this.i18n.translate('kpi.table.badge')
						: this.i18n.translate('kpi.table.badges');
				element.filter = this.kpiListService.filterTable;
				return element;
			}),
			catchError((e) => {
				this.iskpisLoaded = true;
				return e;
			})
		);
	}

	getKpiPoints(event, reload = false) {
		if (!event || !event.length) return;
		this.kpiList = event;
		event.forEach((kpi) => {
			if (kpi.points.length && !reload) return;
			const subscription = this.kpiListService
				.getKpiPoints(kpi.id)
				.pipe(
					tap((points) => {
						kpi.points = points;
						kpi.indicator = this.kpiListService.genericIndicatorRender(kpi, {
							normalize: false,
						});
						kpi.projection = this.kpiListService.genericIndicatorRender(kpi, {
							normalize: true,
						});
						kpi.status = this.kpiListService.statusRender(kpi);
					})
				)
				.subscribe();
			this.loaderService.isLoading.next(false);
			this.subscriptions.push(subscription);
		});
	}

	onNewKpi() {
		this.mixPanelEvent('new_kpi');
		this.stateGo('main.kpiDetailsNew', {});
	}

	onActionCliked(event: Action) {
		switch (event.type) {
		case 'edit':
			this.stateGo('main.kpiDetails', { id: event.id });
			return;
		case 'delete':
			this.openDeleteDialog(event);
			break;
		default:
			break;
		}
	}

	openDeleteDialog(row) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		dialogModel.icon = 'error';
		dialogModel.iconType = 'error';
		dialogModel.title = this.i18n.translate('global.modal.delete-title');
		dialogModel.message = this.i18n.translate('global.modal.delete-message');
		dialogModel.btnError = true;

		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '407px',
		});

		const subscription = this.dialogRef.componentInstance.confirm
			.pipe(tap(() => this.delete(row)))
			.subscribe();
		this.subscriptions.push(subscription);
	}

	delete(kpi) {
		const subscription = this.kpiListService
			.deleteKpi(kpi)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate('kpi.msg-delete-success'),
					});
					if (this.dialogRef) this.closeDialog();
					this.listKpis();
				}),
				catchError((e) => {
					this.notification.showErrorMessages(e);
					return e;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	closeDialog() {
		this.dialogRef.close();
	}

	stateGo(route, params) {
		this.$state.transitionTo(route, params);
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}
}

export const ng2KpiListComponent = {
	name: 'kpiList',
	def: downgradeComponent({
		component: KpiListComponent,
		propagateDigest: true,
	}),
};
