import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import {
	ColumnType,
	DataTableList,
} from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { ContractService } from 'webapp/app/shared/services/contract.service';

@Injectable({
	providedIn: 'root',
})
export class FreeTariffListService {
	displayTable$: Observable<DataTableList> = new Observable();
	list: DataTableList = {} as DataTableList;

	constructor(
		private i18n: TranslocoService,
		private contractService: ContractService
	) {}

	get columnHeaders(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('contract.table.name'),
				type: 'text',
				name: 'name',
				isExpanded: true,
				expandedName: 'cycles',
				width: '20%',
			},
			{
				label: this.i18n.translate('contract.table.energy-type'),
				type: 'text',
				name: 'type',
				width: '20%',
			},
			{
				label: this.i18n.translate('contract.table.contracted-volume'),
				type: 'text',
				name: 'volume',
				width: '20%',
			},
			{
				label: this.i18n.translate('contract.table.energy-price'),
				type: 'text',
				name: 'price',
				width: '20%',
			},
			{
				label: this.i18n.translate('contract.table.validity'),
				type: 'custom',
				name: 'validity',
				width: '20%',
			},
			{
				label: '',
				type: 'action',
				name: 'actions',
				width: '45px',
			},
		];
	}

	get actions(): Action[] {
		return [
			{
				title: this.i18n.translate('contract.table.edit'),
				icon: 'fa-edit',
				type: 'edit',
			},
			{
				title: this.i18n.translate('contract.table.new-cycle'),
				icon: 'fa-calendar-plus',
				type: 'new-cycle',
			},
			{
				title: this.i18n.translate('global.delete'),
				icon: 'fa-trash',
				type: 'delete',
			},
		];
	}

	get subColumns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('contract.table.name'),
				type: 'text',
				name: 'name',
				width: '20%',
			},
			{
				label: this.i18n.translate('contract.table.energy-type'),
				type: 'text',
				name: 'type',
				width: '20%',
			},
			{
				label: this.i18n.translate('contract.table.contracted-volume'),
				type: 'text',
				name: 'volume',
				width: '20%',
			},
			{
				label: this.i18n.translate('contract.table.energy-price'),
				type: 'text',
				name: 'price',
				width: '20%',
			},
			{
				label: this.i18n.translate('contract.table.validity'),
				type: 'custom',
				name: 'validity',
				width: '20%',
			},
			{
				label: '',
				type: 'action',
				name: 'actions',
				width: '45px',
			},
		];
	}

	getSubActions(instance): Action[] {
		return [
			{
				title: this.i18n.translate('contract.table.edit-cycle'),
				icon: 'fa-edit',
				type: 'edit',
				row: instance,
			},
			{
				title: this.i18n.translate('contract.table.duplicate'),
				icon: 'fa-clone',
				type: 'clone',
				row: instance,
			},
			{
				title: this.i18n.translate('global.delete'),
				icon: 'fa-trash',
				type: 'delete',
				row: instance,
			},
		];
	}

	getTariffs(organizationId: number): Observable<DataTableList> {
		const params = {
			subType: 'free',
			organizationId,
		};
		return this.contractService.__getTariffs(params).pipe(
			map((res: any) => {
				return this.buildTable(res);
			})
		);
	}

	buildTable(tariffs: any[]): DataTableList {
		const datatable: DataTableList = {} as DataTableList;
		datatable.data = tariffs.map((tariff: any) => ({
			id: tariff.id,
			name: tariff.name,
			type:
				tariff.fields && this.getEnergyType(tariff.fields.incentivizedEnergy),
			volume:
				tariff.fields?.amountContractedEnergy &&
				`${tariff.fields.amountContractedEnergy} MW/Médio`,
			price: tariff.fields?.energyRate && `${tariff.fields.energyRate} R$/MWh`,
			validity: this.renderValidity(tariff),
			action: this.actions,
			cycles: [],
		}));
		datatable.column = this.columnHeaders;
		datatable.badge =
			tariffs.length === 1
				? this.i18n.translate('contract.table.badge')
				: this.i18n.translate('contract.table.badges');
		datatable.filter = this.filterTable;
		datatable.sub = this.subColumns;

		return datatable;
	}

	renderValidity(instance) {
		if (!instance.startDate || !instance.endDate) {
			return '-';
		}

		const startDate = DateTime.fromISO(instance.startDate);
		const endDate = DateTime.fromISO(instance.endDate);
		const today = DateTime.now();

		const secondLine = this.i18n.translate('contract.tariff.expired-text');

		if (endDate > today) {
			return `
				<span class="flex-col">
					${startDate.toFormat('dd/MM/yyyy')} - ${endDate.toFormat('dd/MM/yyyy')}
				</span>
			`;
		} else {
			return `
				<span class="flex-col expired">
					${startDate.toFormat('dd/MM/yyyy')} - ${endDate.toFormat('dd/MM/yyyy')}
					<div>${secondLine}</div>
				</span>
			`;
		}
	}

	renderCycles(contractId: number) {
		return this.contractService.__getTariffInstances(contractId).pipe(
			map((res: any) => {
				return res.tariffInstances
					.reverse()
					.map((instance: any, index: number) => {
						return {
							id: instance.id,
							tariffId: instance.tariff,
							name: `Ciclo ${index + 1}`,
							type: this.getEnergyType(instance.fields.incentivizedEnergy),
							volume:
								instance.fields?.amountContractedEnergy &&
								`${instance.fields.amountContractedEnergy} MW/Médio`,
							price:
								instance.fields?.energyRate &&
								`${instance.fields.energyRate} R$/MWh`,
							validity: this.renderValidity(instance),
							action: this.getSubActions(instance),
						};
					})
					.reverse();
			})
		);
	}

	getEnergyType(type: boolean) {
		return type
			? this.i18n.translate('contract.table.incentivized-type')
			: this.i18n.translate('contract.table.conventional-type');
	}

	filterTable(data: any, filterText: string) {
		const filter = filterText.toLowerCase();
		return (
			data.name.toLowerCase().includes(filter) ||
			data.type.toLowerCase().includes(filter) ||
			(data.volume && data.volume.toString().toLowerCase().includes(filter)) ||
			(data.price && data.price.toString().toLowerCase().includes(filter)) ||
			(data.validity && data.validity.toLowerCase().includes(filter))
		);
	}
}
