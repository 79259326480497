import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { StateParams } from 'angular-ui-router';
import { CompanyService } from 'webapp/app/shared/services/company.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { CompanyParams } from '../../registrations/companies/models/company.model';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
	SelectorGroup,
	SelectorOption,
} from 'webapp/app/shared/models/selector.model';
import { EMPTY, Subscription, from } from 'rxjs';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { CCEEService } from 'webapp/app/shared/services/ccee.service';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import { DistributorService } from 'webapp/app/shared/services/distributor.service';
import { ContractService } from 'webapp/app/shared/services/contract.service';
import { GaDateRangePickerService } from 'webapp/app/shared/ga-date-range-picker/service/ga-date-range-picker.service';

@Component({
	selector: 'contract-details',
	templateUrl: './contract-details.component.html',
	styleUrls: ['./contract-details.component.scss'],
})
export class ContractDetailsComponent implements OnDestroy, OnInit {
	organizationId!: number;
	contractId!: number;
	cycleId!: number;
	contractForm!: FormGroup;
	cycleForm!: FormGroup;
	companies: SelectorOption[] = [];
	profiles: SelectorGroup[] = [];
	profilesByCompany: SelectorOption[] = [];
	subscriptions: Subscription[] = [];
	subMarkets: SelectorOption[] = [];
	energyTypes: SelectorOption[] = [];
	dateRange = new FormControl();
	formattedDate = new FormControl();
	freeDistributor: any;
	freeTariffType: any;

	months: string[] = [
		'contract.instance.months.jan',
		'contract.instance.months.feb',
		'contract.instance.months.mar',
		'contract.instance.months.apr',
		'contract.instance.months.may',
		'contract.instance.months.jun',
		'contract.instance.months.jul',
		'contract.instance.months.aug',
		'contract.instance.months.sep',
		'contract.instance.months.oct',
		'contract.instance.months.nov',
		'contract.instance.months.dec',
	];

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		private companyService: CompanyService,
		private notification: NotificationService,
		private cceeService: CCEEService,
		@Inject(NG_ROUTER) private $state: any,
		private i18n: TranslocoService,
		private datePipe: DatePipe,
		private distributorService: DistributorService,
		private contractService: ContractService,
		private datePickerService: GaDateRangePickerService
	) {}

	get mode() {
		if (this.$stateParams.contractId && this.$stateParams.duplicateId) {
			return 'duplicate-cycle';
		} else if (this.$stateParams.contractId && this.$stateParams.cycleId) {
			return 'edit-cycle';
		} else if (this.$stateParams.contractId && !this.$stateParams.cycleId) {
			return 'new-cycle';
		} else if (this.$stateParams.id) {
			return 'edit-contract';
		} else {
			return 'new-contract';
		}
	}

	get isContractMode() {
		return this.mode === 'new-contract' || this.mode === 'edit-contract';
	}

	get isDisabled() {
		return this.mode === 'edit-contract'
			? this.contractForm.invalid
			: this.contractForm.invalid || this.cycleForm.invalid;
	}

	ngOnInit(): void {
		this.initOptions();
		this.loadSubMarkets();
		this.loadFreeDistributor();
		this.loadTariffTypes();
		this.initForms();
		this.loadContract();
		this.loadCycle();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	initOptions() {
		const translateLabels = [
			'contract.table.conventional-type',
			'contract.table.incentivized-type',
		];
		this.subscriptions.push(
			this.i18n
				.selectTranslate(translateLabels)
				.pipe(
					tap((labels) => {
						this.energyTypes = [
							{
								label: labels[0],
								id: false,
							},
							{
								label: labels[1],
								id: true,
							},
						];
					})
				)
				.subscribe()
		);
	}

	initForms() {
		this.initContractForm();

		if (this.mode === 'edit-contract') return;

		this.initCycleForm();
	}

	initContractForm() {
		const contract = {
			id: new FormControl(),
			organization: new FormControl('', Validators.required),
			name: new FormControl('', Validators.required),
			company: new FormControl(null),
			code: new FormControl(),
			CCEEProfile: new FormControl(),
			CCEESubmarket: new FormControl(),
		};

		this.contractForm = new FormGroup(contract);

		this.onCompanyChange();
		this.onProfileChange();
	}

	initCycleForm() {
		const seasonalityControls = {};

		for (let i = 1; i <= 12; i++) {
			seasonalityControls[`seasonality-${i}`] = new FormControl();
		}

		const cycle = {
			id: new FormControl(),
			tariff: new FormControl(),
			endDate: new FormControl('', Validators.required),
			startDate: new FormControl('', Validators.required),
			fields: new FormGroup({
				amountContractedEnergy: new FormControl('', Validators.required),
				incentivizedEnergy: new FormControl('', Validators.required),
				energyRate: new FormControl('', Validators.required),
				energyLosses: new FormControl('', Validators.required),
				loadPercentage: new FormControl('', Validators.required),
				minFlexibility: new FormControl(),
				maxFlexibility: new FormControl(),
				TUSDDiscount: new FormControl(),
				CCEETUSDDiscountValue: new FormControl(),
				proinfaDiscount: new FormControl(false),
				seasonalitySection: new FormControl(false),
				minSeasonality: new FormControl(),
				maxSeasonality: new FormControl(),
				...seasonalityControls,
			}),
		};

		this.cycleForm = new FormGroup(cycle);

		this.onSeasonalityChange();
	}

	changeDate() {
		const startDate = this.datePipe.transform(
			this.dateRange.value.start,
			'yyyy-MM-ddTHH:mm:ssZ'
		);
		this.cycleForm.controls['startDate'].setValue(startDate);

		const endDate = this.datePipe.transform(
			this.dateRange.value.end,
			'yyyy-MM-ddTHH:mm:ssZ'
		);
		this.cycleForm.controls['endDate'].setValue(endDate);
	}

	loadSubMarkets() {
		const subscription = this.cceeService
			.__getCCEESubMarkets()
			.pipe(
				tap((markets) => {
					this.subMarkets = markets.map((market) => ({
						id: market.id,
						label: market.name,
					}));

					this.sortAlphabetically(this.subMarkets);
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	loadFreeDistributor() {
		const subscription = from(this.distributorService.getDistributor(0))
			.pipe(
				tap((distributor) => {
					this.freeDistributor = distributor;
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	loadTariffTypes() {
		const subscription = from(
			this.contractService.tariffTypes({ subType: 'free' })
		)
			.pipe(
				tap((types) => {
					this.freeTariffType = types[0];
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	loadContract() {
		if (this.mode === 'new-contract') return;

		let tariff: any;
		const id =
			this.mode === 'edit-contract'
				? this.$stateParams.id
				: this.$stateParams.contractId;

		const subscription = this.contractService
			.__getTariff(id)
			.pipe(
				map((res: any) => res.tariff),
				switchMap((tariffRes) => {
					tariff = tariffRes;
					return this.loadCompanies(tariff.organization);
				}),
				tap(() => {
					this.contractForm.controls['company'].setValue(tariff.company);
					this.contractForm.patchValue(tariff);
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	loadCycle() {
		if (this.mode !== 'edit-cycle' && this.mode !== 'duplicate-cycle') return;

		const contractId = this.$stateParams.contractId;
		const cycleId =
			this.mode === 'edit-cycle'
				? this.$stateParams.cycleId
				: this.$stateParams.duplicateId;

		const subscription = this.contractService
			.getCycle(contractId, cycleId)
			.pipe(
				map((res: any) => res.tariffInstance),
				tap((instance) => {
					this.cycleForm.patchValue(instance);

					const startDate = new Date(this.cycleForm.value.startDate);
					const endDate = new Date(this.cycleForm.value.endDate);

					this.formattedDate.setValue(
						this.datePickerService.formatRange(startDate, endDate, 'custom')
					);
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	orgSelected(event: { id: number; name: string }) {
		this.organizationId = event.id;
		this.contractForm.controls['organization'].setValue(this.organizationId);

		if (!this.organizationId) return;
		const subscription = this.loadCompanies(this.organizationId).subscribe();
		this.subscriptions.push(subscription);
	}

	loadCompanies(organizationId) {
		const query: CompanyParams = {
			organization: organizationId,
		};

		return this.companyService.__getCompanies(query).pipe(
			tap((res: any) => {
				this.companies = res.companies.map((company) => ({
					id: company.id,
					label: company.companyName,
				}));

				this.profiles = res.companies.map((company) => ({
					id: company.id,
					label: company.companyName,
					options: company.CCEEProfiles.map((profile) => ({
						id: profile.id,
						label: profile.name,
						extra: profile.CCEESubMarket,
					})),
				}));
			}),
			catchError((error) => {
				this.notification.showErrorMessages(error);
				return EMPTY;
			})
		);
	}

	onCompanyChange() {
		const subscription = this.contractForm.controls[
			'company'
		].valueChanges.subscribe((companyId) => {
			if (!companyId) return;

			this.profilesByCompany = this.profiles.find(
				(profile) => profile.id === companyId
			)!.options;
		});

		this.subscriptions.push(subscription);
	}

	onProfileChange() {
		const subscription = this.contractForm.controls[
			'CCEEProfile'
		].valueChanges.subscribe((value) => {
			if (!this.profilesByCompany.length) return;

			const subMarketId = this.profilesByCompany.find(
				(profile) => profile.id === value
			)!.extra;

			this.contractForm.controls['CCEESubmarket'].setValue(subMarketId);
		});

		this.subscriptions.push(subscription);
	}

	onSeasonalityChange() {
		const subscription = this.cycleForm
			.get('fields.seasonalitySection')!
			.valueChanges.subscribe((value) => {
				if (value) return;

				this.cycleForm.get('fields.minSeasonality')!.reset();
				this.cycleForm.get('fields.maxSeasonality')!.reset();

				for (let i = 1; i <= 12; i++) {
					this.cycleForm.get(`fields.seasonality-${i}`)!.reset();
				}
			});

		this.subscriptions.push(subscription);
	}

	onSubmit() {
		if (this.isContractMode) {
			this.saveContract();
		} else {
			this.saveCycle(this.contractForm.value.id);
		}
	}

	saveContract() {
		const contract = this.contractForm.value;
		contract.tariffType = this.freeTariffType;

		contract.distributorId =
			this.mode === 'new-contract'
				? this.freeDistributor
				: this.freeDistributor.id;

		const subscription = this.contractService
			.__saveTariff(contract)
			.pipe(
				tap((res: any) => {
					this.notification.success({
						msg: this.i18n.translate('contract.notify.save.contract'),
					});

					if (this.mode === 'new-contract') {
						this.saveCycle(res.tariff.id);
					} else {
						this.backTo();
					}
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	saveCycle(contractId) {
		const cycle = this.cycleForm.value;
		this.convertToNumber(cycle.fields);
		cycle.tariff = contractId;

		if (this.mode === 'duplicate-cycle') {
			cycle.id = null;
		}
		cycle.fields.discountSection =
			!!cycle.fields.CCEETUSDDiscountValue || !!cycle.fields.TUSDDiscount;
		cycle.fields.CCEETUSDDiscountValue =
			cycle.fields.CCEETUSDDiscountValue || null;
		const subscription = this.contractService
			.saveCycle(cycle)
			.pipe(
				tap((res) => {
					this.notification.success({
						msg: this.i18n.translate('contract.notify.save.cycle'),
					});
					this.backTo();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	backTo() {
		this.$state.transitionTo('contracts', { tab: 'free' });
	}

	convertToNumber(fields) {
		Object.keys(fields).forEach((key) => {
			if (
				key !== 'incentivizedEnergy' &&
				key !== 'proinfaDiscount' &&
				key !== 'seasonalitySection' &&
				!isNaN(fields[key])
			) {
				fields[key] = Number(fields[key]);
			}
		});
	}

	sortAlphabetically(options: SelectorOption[]) {
		options.sort((a, b) => {
			if (a.label < b.label) {
				return -1;
			}
			if (a.label > b.label) {
				return 1;
			}
			return 0;
		});
	}
}

export const ng2ContractDetailsComponent = {
	name: 'contractDetails',
	def: downgradeComponent({
		component: ContractDetailsComponent,
		propagateDigest: true,
	}),
};
