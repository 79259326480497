<div class="content-wrapper view">
	<div class="container-fluid">
		<nav class="ga-navbar">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()"> Contratos e tarifas </a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()"> Contratos </a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<ng-container [ngSwitch]="mode">
						<span
							*ngSwitchCase="'new-contract'"
							class="navbar-content__breadcrumb-page"
						>
							{{ "contract.form.title.new-contract" | transloco }}
						</span>
						<span
							*ngSwitchCase="'edit-contract'"
							class="navbar-content__breadcrumb-page"
						>
							{{ "contract.form.title.edit-contract" | transloco }}
						</span>
						<span
							*ngSwitchCase="'edit-cycle'"
							class="navbar-content__breadcrumb-page"
						>
							{{ "contract.form.title.edit-cycle" | transloco }}
						</span>
						<span *ngSwitchDefault class="navbar-content__breadcrumb-page">
							{{ "contract.form.title.new-cycle" | transloco }}
						</span>
					</ng-container>
				</h3>
			</div>
		</nav>

		<div class="description">
			<div class="description__texts">
				<ng-container [ngSwitch]="mode">
					<ng-container *ngSwitchCase="'new-contract'">
						<span class="description__texts__title">{{
							"contract.form.title.new-contract" | transloco
						}}</span>
						<span class="description__texts__subtitle">{{
							"contract.form.sub-title.new-contract" | transloco
						}}</span>
					</ng-container>
					<ng-container *ngSwitchCase="'edit-contract'">
						<span class="description__texts__title">{{
							"contract.form.title.edit-contract" | transloco
						}}</span>
						<span class="description__texts__subtitle">{{
							"contract.form.sub-title.edit-contract" | transloco
						}}</span>
					</ng-container>
					<ng-container *ngSwitchCase="'edit-cycle'">
						<span class="description__texts__title">{{
							"contract.form.title.edit-cycle" | transloco
						}}</span>
						<span class="description__texts__subtitle">{{
							"contract.form.sub-title.edit-cycle" | transloco
						}}</span>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<span class="description__texts__title">{{
							"contract.form.title.new-cycle" | transloco
						}}</span>
						<span class="description__texts__subtitle">{{
							"contract.form.sub-title.new-cycle" | transloco
						}}</span>
					</ng-container>
				</ng-container>
			</div>
		</div>

		<form (ngSubmit)="onSubmit()" focusInvalidInput class="flex-col">
			<div class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "global.basic-info" | transloco }}
				</h3>
				<div class="row" [formGroup]="contractForm">
					<div class="form-group col-lg-4">
						<autocomplete-orgs
							[idSelected]="organizationId"
							(orgEmitter)="orgSelected($event)"
							[isRequired]="true"
							[isEnabled]="isContractMode"
						></autocomplete-orgs>
					</div>
					<div class="form-group col-lg-4">
						<ga-input
							[title]="'contract.form.label' | transloco"
							[placeholder]="'contract.form.label-placeholder' | transloco"
							[disabled]="!isContractMode"
							[required]="true"
							[showError]="
								contractForm.controls['name'].invalid &&
								contractForm.controls['name'].touched
							"
							formControlName="name"
							name="name"
						></ga-input>
					</div>
					<div class="form-group col-lg-4">
						<ga-selector
							[title]="'contract.form.company' | transloco"
							[placeholder]="'contract.form.company-placeholder' | transloco"
							[disabled]="!isContractMode"
							[options]="companies"
							formControlName="company"
							name="company"
						></ga-selector>
					</div>
					<div class="form-group col-lg-4">
						<ga-input
							[title]="'contract.form.number' | transloco"
							[placeholder]="'contract.form.number-placeholder' | transloco"
							[disabled]="!isContractMode"
							formControlName="code"
							name="code"
							type="number"
						></ga-input>
					</div>
					<div class="form-group col-lg-4">
						<ga-selector
							[title]="'contract.form.ccee' | transloco"
							[placeholder]="'contract.form.ccee-placeholder' | transloco"
							[disabled]="!isContractMode"
							[options]="profilesByCompany"
							formControlName="CCEEProfile"
							name="CCEEProfile"
						></ga-selector>
					</div>
					<div class="form-group col-lg-4">
						<ga-selector
							[title]="'contract.form.submarket' | transloco"
							[placeholder]="'contract.form.submarket-placeholder' | transloco"
							[disabled]="!isContractMode"
							[options]="subMarkets"
							formControlName="CCEESubmarket"
							name="CCEESubmarket"
						></ga-selector>
					</div>
				</div>

				<ng-container *ngIf="mode !== 'edit-contract'" [formGroup]="cycleForm">
					<hr class="separator" />
					<div class="row form-group">
						<h3 class="basic-data__title col-lg-12">
							{{
								mode === "new-contract"
									? ("contract.form.first-cycle-info" | transloco)
									: ("contract.form.cycle-info" | transloco)
							}}
						</h3>

						<div class="form-group col-lg-3">
							<ga-date-range-picker
								[title]="'contract.form.contract-validity' | transloco"
								[placeholder]="'00/00/0000 - 00/00/0000'"
								[showTime]="false"
								[showOptions]="false"
								[initialPeriod]="'custom'"
								[required]="true"
								[startWithoutCurrentDate]="true"
								[formControl]="dateRange"
								[formattedDate]="formattedDate"
								[showError]="dateRange.invalid && dateRange.touched"
								(ngModelChange)="changeDate()"
							>
							</ga-date-range-picker>
						</div>

						<ng-container formGroupName="fields">
							<div class="form-group col-lg-3">
								<ga-input
									[title]="'contract.form.contracted-volume' | transloco"
									[placeholder]="'meter-page.placeholder.value' | transloco"
									[rAddon]="'contract.form.contracted-volume-unit' | transloco"
									[required]="true"
									[showError]="
										cycleForm.get('fields.amountContractedEnergy')!.invalid &&
										cycleForm.get('fields.amountContractedEnergy')!.touched
									"
									formControlName="amountContractedEnergy"
									name="amountContractedEnergy"
									type="number"
								></ga-input>
							</div>

							<div class="form-group col-lg-3">
								<ga-selector
									[title]="'contract.form.energy-type' | transloco"
									[placeholder]="
										'contract.form.submarket-placeholder' | transloco
									"
									[tooltip]="'contract.form.energy-type-tooltip' | transloco"
									[options]="energyTypes"
									[required]="true"
									[showError]="
										cycleForm.get('fields.incentivizedEnergy')!.invalid &&
										cycleForm.get('fields.incentivizedEnergy')!.touched
									"
									formControlName="incentivizedEnergy"
									name="incentivizedEnergy"
								></ga-selector>
							</div>
						</ng-container>
					</div>

					<div class="row row-group" formGroupName="fields">
						<div class="form-group col-lg-3">
							<ga-input
								[title]="'contract.form.energy-price' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'contract.form.energy-price-unit' | transloco"
								[required]="true"
								[showError]="
									cycleForm.get('fields.energyRate')!.invalid &&
									cycleForm.get('fields.energyRate')!.touched
								"
								formControlName="energyRate"
								name="energyRate"
								type="number"
							></ga-input>
						</div>

						<div class="form-group col-lg-3">
							<ga-input
								[title]="'contract.form.energy-losses' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'%'"
								[required]="true"
								[showError]="
									cycleForm.get('fields.energyLosses')!.invalid &&
									cycleForm.get('fields.energyLosses')!.touched
								"
								formControlName="energyLosses"
								name="energyLosses"
								type="number"
							></ga-input>
						</div>

						<div class="form-group col-lg-3">
							<ga-input
								[title]="'contract.form.load-percentage' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'%'"
								[required]="true"
								[showError]="
									cycleForm.get('fields.loadPercentage')!.invalid &&
									cycleForm.get('fields.loadPercentage')!.touched
								"
								formControlName="loadPercentage"
								name="loadPercentage"
								type="number"
							></ga-input>
						</div>
					</div>

					<hr class="separator" />
					<div class="row form-group">
						<h3 class="basic-data__title col-lg-12">
							{{ "contract.form.flexibility" | transloco }}
						</h3>

						<div class="row row-group" formGroupName="fields">
							<div class="form-group col-lg-3">
								<ga-input
									[title]="'contract.form.min-flexibility' | transloco"
									[placeholder]="'meter-page.placeholder.value' | transloco"
									[rAddon]="'%'"
									formControlName="minFlexibility"
									name="minFlexibility"
									type="number"
								></ga-input>
							</div>

							<div class="form-group col-lg-3">
								<ga-input
									[title]="'contract.form.max-flexibility' | transloco"
									[placeholder]="'meter-page.placeholder.value' | transloco"
									[rAddon]="'%'"
									formControlName="maxFlexibility"
									name="maxFlexibility"
									type="number"
								></ga-input>
							</div>
						</div>
					</div>

					<hr class="separator" />
					<div class="row form-group code-group" formGroupName="fields">
						<h3 class="basic-data__title col-lg-12">
							{{ "contract.form.discounts" | transloco }}
						</h3>

						<div class="form-group col-lg-3">
							<ga-input
								[title]="'contract.form.tusd-discount' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[tooltip]="'contract.form.tusd-tooltip' | transloco"
								[rAddon]="'%'"
								formControlName="TUSDDiscount"
								name="TUSDDiscount"
								type="number"
							></ga-input>
						</div>

						<div class="form-group col-lg-3">
							<ga-input
								[title]="'contract.form.discount-value' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'contract.form.energy-price-unit' | transloco"
								formControlName="CCEETUSDDiscountValue"
								name="CCEETUSDDiscountValue"
								type="number"
							></ga-input>
						</div>

						<div class="form-group col-lg-3">
							<ga-checkbox
								[title]="'contract.form.proinfa-discount' | transloco"
								[isMultiple]="false"
								[boldLabel]="true"
								formControlName="proinfaDiscount"
								name="proinfaDiscount"
							></ga-checkbox>
						</div>
					</div>

					<hr class="separator" />
					<div class="row form-group" formGroupName="fields">
						<h3 class="basic-data__title col-lg-12">
							<span class="mr-5">
								{{ "contract.form.seasonality" | transloco }}
							</span>
							<ga-helper
								[text]="'contract.form.seasonality-tooltip' | transloco"
								[icon]="'fa-solid fa-circle-info'"
								[iconSize]="14"
								[iconColor]="'#A3A3A3'"
								[tooltipLocale]="'above'"
								class="helper"
							></ga-helper>
						</h3>

						<div class="form-group col-lg-3">
							<ga-checkbox
								[title]="'contract.form.seasonality-apply' | transloco"
								[isMultiple]="false"
								[boldLabel]="true"
								formControlName="seasonalitySection"
								name="seasonalitySection"
							></ga-checkbox>
						</div>
					</div>

					<div
						*ngIf="cycleForm.controls['fields'].value.seasonalitySection"
						class="row row-group"
						formGroupName="fields"
					>
						<div class="form-group col-lg-3">
							<ga-input
								[title]="'contract.form.min-flexibility' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'%'"
								formControlName="minSeasonality"
								name="minSeasonality"
								type="number"
							></ga-input>
						</div>

						<div class="form-group col-lg-3">
							<ga-input
								[title]="'contract.form.max-flexibility' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'%'"
								formControlName="maxSeasonality"
								name="maxSeasonality"
								type="number"
							></ga-input>
						</div>
					</div>

					<div
						*ngIf="cycleForm.controls['fields'].value.seasonalitySection"
						class="row row-group"
						formGroupName="fields"
					>
						<div
							*ngFor="let month of months; let i = index"
							class="form-group col-lg-3 col-md-6"
						>
							<ga-input
								[title]="month | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'contract.form.contracted-volume-unit' | transloco"
								[formControlName]="'seasonality-' + (i + 1)"
								type="number"
							></ga-input>
						</div>
					</div>
				</ng-container>

				<hr class="separator" />
				<div class="button-group flex-row">
					<div class="btn-toolbar" role="toolbar" style="text-align: center">
						<div class="btn-group" role="group">
							<button class="btn btn-default" type="button" (click)="backTo()">
								{{ "appliance.btn.cancel" | transloco }}
							</button>
						</div>
						<div class="btn-group" role="group">
							<button
								class="btn btn-primary btn-color-ga"
								type="submit"
								[disabled]="isDisabled"
							>
								{{ "organization.btn.save" | transloco }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
